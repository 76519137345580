import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { ReactComponent as YangspirationLogo } from "../images/y-logo.svg"


import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <h1>Hello</h1>
    <p>Welcome to your new Gatsby site.</p>
    <StaticImage
      src="../images/y-logo.svg"
      width={300}
      style={{ marginBottom: `1.45rem` }}
    />
    <p>
      {/* <Link to="/page-2/">Go to page 2</Link> <br />
      <Link to="/using-typescript/">Go to "Using TypeScript"</Link> */}
    </p>
  </Layout>
)

export default IndexPage
